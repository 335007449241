import {IAvailabilityEffect} from 'src/api/SecurityCardsKRITIS/EffectsApi';
import {AvailabilityTag} from 'src/api/SecurityCardsKRITIS/SecurityEventsApi';
import Effect from './Effect';

/** extension to effect with income values */
export default class AvailabilityEffect extends Effect {
  availability:number;
  tag:AvailabilityTag;
  failureDays?:number|undefined;

  /** default constructor */
  constructor(data:IAvailabilityEffect) {
    super(data);
    this.availability = data.availability;
    this.tag = data.tag;
    this.failureDays = data.failureDays;
  }
}

/** typeguard for incomeEffects */
export function isAvailabilityEffect(effect:Effect):
effect is AvailabilityEffect {
  return (effect as AvailabilityEffect).availability != undefined;
}
