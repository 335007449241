import React from 'react';
import {
  Route, BrowserRouter, Routes,
} from 'react-router-dom';

// import PhishingQuiz from 'src/components/PhishingQuiz/PhishingQuiz';
// import PhishingPage from
//   'src/components/PhishingQuiz/LandingPages/PhishingPage';
// import CompanyPage from
//   'src/components/PhishingQuiz/LandingPages/CompanyPage';
import SecurityCards from 'src/components/SecurityCardsKRITIS/SecurityCards';
import Home from 'src/components/Home/Home';
// import PhishingAttachmentPage from
//   'src/components/PhishingQuiz/LandingPages/PhishingAttachmentPage';
// import TrustedAttachmentPage from
//   'src/components/PhishingQuiz/LandingPages/TrustedAttachmentPage';
// import ThreatAttack from 'src/components/ThreatAttack/ThreatAttack';
// import ReadMorePhishingQuiz from
//   'src/components/PhishingQuiz//ReadMore/ReadMore';
import ReadMoreSecCards from
  'src/components/SecurityCardsKRITIS/ReadMore/ReadMore';

/** MainRouter component contains top level links to each game */
function MainRouter() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<Home/>} />
        {/* A <Switch> looks through its children <Route>s and
            renders the first one that matches the current URL. */}
        {/* <Route path='/PhishingQuiz/PhishingPage' element={<PhishingPage/>}/>
        <Route path='/PhishingQuiz/CompanyPage' element={<CompanyPage/>} />
        <Route path='/PhishingQuiz/PhishingAttachment'
          element={<PhishingAttachmentPage/>} />
        <Route path='/PhishingQuiz/TrustedAttachment'
          element={<TrustedAttachmentPage/>} />
        <Route path='/PhishingQuiz' element={<PhishingQuiz/>} />
        <Route path='/PhishingQuiz/short' element={<PhishingQuiz/>} />
        <Route path='/PhishingQuiz/AdditionalInfo'
          element={<ReadMorePhishingQuiz/>} /> */}
        <Route path='/SecurityCardsKritis' element={<SecurityCards/>}/>
        <Route path='/SecurityCardsKritis/short' element={<SecurityCards/>} />
        <Route path='/SecurityCardsKritis/AdditionalInfo'
          element={<ReadMoreSecCards/>} />
        {/* <Route path='/ThreatAttack' element={<ThreatAttack/>} />
        <Route path='/ThreatAttack/short' element={<ThreatAttack/>} />
        <Route path='/ThreatAttack/AdditionalInfo'
          element={null} /> */}
      </Routes>
    </BrowserRouter>
  );
}

export default MainRouter;
