import React from 'react';
import {observer} from 'mobx-react';
import './StatusBar.scss';
import GameState
  from 'src/components/SecurityCardsKRITIS/logic/GameState';
import EffectViewContainer from '../EffectView/EffectViewContainer';
import AvailabilityIcons from '../AvailabilityIcons/AvailabilityIcons';

interface StatsViewProps {
  gameState: GameState
}

interface StatusBarState {
  visibleMoney: boolean
}

/**
 * Class for the Status bar, displaying
 * left side elements.
 */
@observer
class StatusBar extends React.Component<StatsViewProps, StatusBarState> {
  /** default constructor */
  constructor(props: StatsViewProps) {
    super(props);
    this.state = {
      visibleMoney: false,
    };
  }

  /** Toggle money visibility **/
  toggleMoneyVisibility = ()=> {
    this.setState({visibleMoney: !this.state.visibleMoney});
  };

  /** handle button click.
   * either go to next turn when event description is not toggled or
   * check if current active event occurs in the game state.
   * If so, add the effect and toggle the event description.
  **/
  buttonClick = () => {
    if (!this.props.gameState.showEventDescription) {
      this.props.gameState.nextTurn();
      this.props.gameState.toggleEventDescription();
    } else {
      this.props.gameState.checkEvent();
      this.props.gameState.toggleProbMeterAnimation();
    }
    this.tutorialStepCount();
  };
  toggleShop = () => {
    this.props.gameState.shop.toggleShopVisibility();
    this.tutorialStepCount();
  };
  /** count up tutorial steps */
  tutorialStepCount() {
    switch (this.props.gameState.tutorialStep) {
      case 1:
      case 2:
      case 3:
      case 4:
      case 7:
        this.props.gameState.tutorialStep += 1;
        break;
    }
  }
  /** display arrow in tutorial steps */
  tutorialArrowRender() {
    switch (this.props.gameState.tutorialStep) {
      case 1:
      case 2:
      case 3:
      case 7:
      case 8:
        return true;
        break;
    }
  }
  /** Render the StatView and its elements **/
  render() {
    const showAvailability = this.props.gameState.getAvailabilityVisibility();
    return (
      <div className='StatusBar'>
        <button
          className={
            (this.props.gameState.tutorialStep == 1 ||
            this.props.gameState.tutorialStep == 7)?
            'NextButton NextButtonGlow':
            'NextButton'}
          disabled={(!this.props.gameState.nextTurnEnabled() &&
             !this.props.gameState.showEventDescription )||
             this.props.gameState.probMeterIsMoving}
          title={
            (!this.props.gameState.nextTurnEnabled() &&
            !this.props.gameState.showEventDescription)?
            'Aktion erforderlich!':''}
          onClick={() => this.buttonClick()}>
          {this.props.gameState.showEventDescription ? 'OK' : 'Nächste Woche'}
        </button>

        <button
          className={
            this.props.gameState.tutorialStep == 4 ?
            'ShopButton ShopButtonGlow':
            'ShopButton'}
          disabled={!this.props.gameState.shopAvailable}
          onClick={()=> this.toggleShop()}
          data-arrow={
            this.props.gameState.tutorialStep == 4 ?
            '→': ''}
        >Kartenshop</button>

        {showAvailability? <AvailabilityIcons gameState={this.props.gameState}>
        </AvailabilityIcons> : '' }

        <EffectViewContainer
          effects={this.props.gameState.activeEffects.awarenessLevels}/>
      </div>
    );
  }
}

export default StatusBar;
