import React from 'react';
import CountUp from 'react-countup';
import {formatMoney} from 'src/utils/Formatters/Formatters';

interface MoneyCountUpProps {
  initial: number;
  endMoney: number;
}

interface MoneyCountUpState {
  startMoney: number;
}


/** isolated countup Component */
class MoneyCountUp extends React.Component<MoneyCountUpProps,
  MoneyCountUpState> {
  /** constructor sets startMoney to inital value */
  constructor(props: MoneyCountUpProps) {
    super(props);
    this.state = {
      startMoney: props.initial,
    };
  }

  /** only rerender if startMoney and endMoney are
   * different or endMoney gets updated
   * to prevent rerender after didUpdate hook */
  shouldComponentUpdate = (nextProps: MoneyCountUpProps,
      nextState: MoneyCountUpState) => {
    if (this.props.endMoney != nextState.startMoney) {
      return true;
    } else if (this.props.endMoney != nextProps.endMoney) {
      return true;
    } else {
      return false;
    }
  };

  /** write endmoney to prev startmoney after update*/
  componentDidUpdate = (prevProps: MoneyCountUpProps) => {
    this.setState({
      startMoney: prevProps.endMoney},
    );
  };

  /** default render */
  render() {
    return (<CountUp className='Money'
      start={this.state.startMoney}
      end={this.props.endMoney}
      preserveValue
      redraw
      formattingFn={(num) => {
        return 'Geld: ' + formatMoney(num);
      }} />
    );
  }
}

export default MoneyCountUp;
