import React from 'react';
import './RedundancyBar.scss';
import {AVAILABILITY_CAP} from '../logic/effects/Effects';

interface RedundancyBarProps {
    icon: string,
    redundancy: number,
    tooltip: string,
}

/** Component to render single effect, currently supports ProbabilityEffects */
export default class RedundancyBar extends React.Component<RedundancyBarProps> {
  /** render function */
  render() {
    return (
      <div className='RedundancyWrapper'>
        <img className="RedundancyIcon" title={this.props.tooltip}
          src={this.props.icon} />
        <div className='RedundancyBar'>
          {Array.from({length: (AVAILABILITY_CAP + 1)}, (_, i) => (
            <div className={`RedundancyItem` +
          `${this.props.redundancy > i ? ' highlight' : ''}`} key={i} />
          ))}
        </div>
      </div>
    );
  }
}
