import React from 'react';
import {Link} from 'react-router-dom';
import './Home.scss';
import TopBar from './TopBar/TopBar';
import HomeCard from './HomeCard/HomeCard';
import SecurityCardsScr from 'src/assets/Home/Screenshots/SecurityCards.png';

/** Home component with link elements*/
function Home() {
  return (
    <div className="Home">
      <TopBar/>
      <div className='HomeFlex'>
        <Link className='HomeCardLink' to={'/SecurityCardsKritis'}
          id='SecurityCardsLink'>
          <HomeCard title='SecurityCards: KRITIS Edition'
            description='In SecurityCards spielen Sie einen
            Entscheidungsträger für ein fiktives Unternehmen. Sie
            treffen die Entscheidung in welche IT-Absicherungsmaßnahmen
            Sie investieren wollen.'
            image={SecurityCardsScr}/>
        </Link>
      </div>
    </div>
  );
}

export default Home;
