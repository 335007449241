import React from 'react';

import './SecurityCardsExplainPage.scss';
import ExplainPage from 'src/utils/ExplainPage/ExplainPage';
import IntroGif from
  '../../../assets/SecurityCardsKRITIS/SecurityCardsIntro.gif';
import avIcon from 'src/assets/SecurityCardsKRITIS/AG_Avail.svg';
import ShieldIcon from 'src/assets/SecurityCardsKRITIS/Shield.svg';


export interface SecurityCardsExplainPageProps {
  clickEvent: () => void,
}

/** The Explain Page, which can be displayed
 * just before the Game starts
 **/
class SecurityCardsExplainPage extends
  React.Component<SecurityCardsExplainPageProps> {
  /** Constructor taking a onClick function for the button**/
  constructor(props: SecurityCardsExplainPageProps) {
    super(props);
  }

  /** Render The Explain text **/
  render() {
    return (
      <div>
        <ExplainPage
          title="SecurityCards: KRITIS Edition"
          subtitle='Anleitung'
          clickEvent={this.props.clickEvent}>
          <div className="ExplainText">
            <div className='IntroGif'>
              <img src={IntroGif} alt='Intro' height={405} width={850}/>
            </div>
            In SecurityCards: KRITIS Edition spielen Sie einen
            Entscheidungsträger für ein Unternehmen der kritischen
            Infrastruktur.
            Abhängig davon für welches Unternehmen Sie sich entscheiden,
            werden Sie mit unterschiedlichen Ereignissen konfrontiert.
            <br /><br />
            Informationen über den Kontostand und der Verfügbarkeit Ihres
            Unternehmens erhalten Sie durch die entsprechenden Anzeigen oben
            rechts.
            <br /><br />
            Ziel des Spiels ist es, den Kontostand im Plus zu halten und dafür
            zu sorgen, dass die Verfügbarkeit einen bestimmten Schwellenwert
            nicht unterschreitet.
            Der Schwellenwert, der nicht unterschritten werden darf, wird Ihnen
            als orangene Markierung auf der Verfügbarkeitsleiste angezeigt.
            Wenn Sie mit der Maus über die Markierung hovern, können Sie den
            genauen Schwellenwert ablesen.
            Damit das Geld und Verfügbarkeit nicht zu tief fallen, müssen Sie
            die auftretenden Bedrohungen für das Unternehmen
            erkennen und passende Gegenmaßnahmen ergreifen.<br /><br />
            Hierfür stehen Ihnen unterschiedliche Karten zur Verfügung.
            Eine Karte kann eine Entscheidung oder eine präventive Investition
            in Schutzmaßnahmen darstellen.
            <br />
          Zusätzliche Karten können im <button className="DesignCardShopBTN">
              KartenShop</button> erworben und aktiviert werden.
            <br />
          Der Spielablauf besteht aus einer bestimmten Anzahl von Kalenderwochen
        (KW), in denen Ereignisse auftreten.
          Das aktuelle Ereignis wird im oberen Bereich des Spiels
            angezeigt.
          Nach Auftreten eines Ereignisses sollten Sie dieses mit Klick
          auf <button className="DesignBTN confirmBTN">OK</button> bestätigen.
            <br /><br />
          Danach kann durch Kauf oder Aktivierung von Karten auf das
          Ereignis reagiert werden.
          Ereignisse können temporär neue Karten generieren.
          Einige Ereignisse erfordern eine Reaktion
          von Ihnen und können nicht ignoriert werden.
          Spielbare Karten werden im unteren Bereich des Spielfeldes angezeigt.
          Karten können für eine Detailansicht angeklickt werden.<br /><br />
          In der Detailansicht kann durch Klick auf <button className=
              "DesignBTN confirmBTN">Aktivieren</button> die Karte angewendet
          werden oder durch <button className="DesignBTN cancelBTN">Abbrechen
            </button> diese Ansicht beendet werden.<br /><br />
          Am linken Bildschirmrand stehen Ihnen verschiedene Informationen zur
          Verfügung, die Ihnen bei der Entscheidung der geeigneten Maßnahmen
          helfen sollen:<br />
          Bei der Redundanz <img className="InlineTextIcon"
              src={avIcon}></img> können Sie sehen, wie gut Ihr Unternehmen auf
          mögliche Ausfälle vorbereitet ist. Sie starten bei jeder Kategorie mit
          einem einzigen Punkt, das bedeutet, dass sie zum Beispiel eine einzige
          Strom- oder Internetleitung besitzen. Über den <button className=
              "DesignCardShopBTN">KartenShop</button> können
          Sie Maßnahmen ergreifen, um die Redundanz zu erhöhen. Durch eine
          zweite Stromleitung kann beispielsweise dafür gesorgt werden, dass
          das Unternehmen erreichbar bleibt, selbst wenn durch Bauarbeiten
          eine Leitung beschädigt wird.<br />
          Unter der Redundanz werden die Resistenzen <img className=
              "InlineTextIcon" src={ShieldIcon}></img> angezeigt. Dieser Bereich
          kann durch einen Klick auf den kleinen Pfeil aufgeklappt werden.
          Hier werden die verschiedenen Kategorien der Resistenzen angezeigt.
          Da zu Beginn noch keine Resistenzen aufgebaut wurden, sind alle
          Fortschrittsbalken leer. Durch Karten aus dem <button className=
              "DesignCardShopBTN">KartenShop</button> können diese Resistenzen
          erhöht werden. Je höher die Resistenzen sind, desto höher ist
          die Wahrscheinlichkeit, dass ein Angriff fehlschlägt.<br /><br />
          Viel Erfolg!
          </div>
        </ExplainPage>
      </div>
    );
  }
}

export default SecurityCardsExplainPage;
