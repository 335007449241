import React from 'react';
import {observer} from 'mobx-react';
import BAKManLogo from 'src/assets/logo.png';
import IncomeEffect from '../logic/effects/IncomeEffect';
import './TopBar.scss';
import {formatMoney} from 'src/utils/Formatters/Formatters';
import MoneyCountUp from './Countup';
import AvailabilityBar from '../AvailabilityBar/AvailabilityBar';
import GameState from 'src/components/SecurityCardsKRITIS/logic/GameState';
interface StatsViewProps {
  gameState: GameState
}

interface TopBarState {
  visibleMoney: boolean
}

/**
 * Class for the TopBar bar, displaying
 * left side elements.
 */
@observer
class TopBar extends React.Component<StatsViewProps, TopBarState> {
  /** default constructor */
  constructor(props: StatsViewProps) {
    super(props);
    this.state = {
      visibleMoney: false,
    };
  }

  /** Generate a income effect entry **/
  private generateIncomeEffectInfo(effect: IncomeEffect, key: number) {
    return (
      <li className={`${effect.amount >= 0?'Income':'Cost'}`} key={key}>
        <span>{effect.label}:</span> <span>{formatMoney(effect.amount)}</span>
      </li>);
  }

  /** Toggle money visibility **/
  toggleMoneyVisibility = ()=> {
    this.setState({visibleMoney: !this.state.visibleMoney});
  };

  /** Render the StatView and its elements **/
  render() {
    const showAvailability = this.props.gameState.getAvailabilityVisibility();
    const rounds = this.props.gameState.getMaxRounds();
    return (
      <div className='TopBar'>
        <a href="https://baksecure.de/">
          <img className="Logo" alt="BAK Logo" src={BAKManLogo}
            /* width="28" height="28"*/></img>
        </a>
        <span className="GameName">SecurityCards: KRITIS Edition</span>
        {this.props.gameState.tutorialStep > 0?
        <button className='TutorialSkipBTN'
          onClick={()=>this.props.gameState.endTutorial()}>
          Tutorial Überspringen
        </button>: null}
        <div className='TurnCount'>
          KW {this.props.gameState.turn} von {rounds}
        </div>
        <div className='VerticalLine'/>
        {showAvailability? <AvailabilityBar
          gameState={this.props.gameState}></AvailabilityBar> : '' }
        {showAvailability? <div className='VerticalLine'/> : '' }
        <div className='MoneyContainer'>
          <div className='InnerMoneyContainer'
            onClick={this.toggleMoneyVisibility}>
            <MoneyCountUp initial={0} endMoney={this.props.gameState.money}/>
          </div>
          <div className={`MoneyDetails ${this.state.visibleMoney?
              'MoneyVisible':''}`}>
            <ul>
              {
                this.props.gameState.activeEffects.incomeEffects.map(
                    (effect, index) => {
                      return this.generateIncomeEffectInfo(effect, index);
                    },
                )
              }
            </ul>
          </div>
        </div>
      </div>
    );
  }
}

export default TopBar;
