import {IEffect} from 'src/api/SecurityCardsKRITIS/EffectsApi';

/** abstract baseclass for effects */
export default abstract class Effect implements IEffect {
  name: string;
  // set duration to -1 for infinite Effect duration
  duration:number;
  // skillname is defined in gamedata if effect is associated to a skill
  skillName?: string;
  /** default constructor */
  constructor(data:IEffect) {
    this.name = data.name;
    this.duration = data.duration;
  }
}
