import React from 'react';
import './EffectView.scss';

import {Tag, TagIcons} from 'src/api/SecurityCardsKRITIS/SecurityEventsApi';

interface EffectViewProps {
    tag: Tag,
    value: number
}

/** Component to render single effect, currently supports ProbabilityEffects */
export default class EffectView extends React.Component<EffectViewProps> {
  createTooltip = () =>{
    return `${this.props.tag} Resistance:
      ${this.props.value} %`;
  };

  /** render function */
  render() {
    // add cases for cardeffect
    const src = TagIcons[(this.props.tag)];
    return (<div className='EffectView'>
      <div className='flex-container'>
        <img src={src}/>
        <progress className='effectViewProgress'
          value={(this.props.value) / 100}></progress>
      </div>
      <span className="ToolTip">{this.createTooltip()}</span>
    </div>);
  }
}
