import React from 'react';
import './CardView.scss';
import Skill from '../logic/cards/Skill';
import HintWidget from 'src/utils/HintWidget/HintWidget';
import {TagIcons} from 'src/api/SecurityCardsKRITIS/SecurityEventsApi';
import income from 'src/assets/SecurityCardsKRITIS/IncomeSkillBG.svg';
import preventive from 'src/assets/SecurityCardsKRITIS/PreventiveSkillBG.svg';
import reactive from 'src/assets/SecurityCardsKRITIS/ReactiveSkillBG.svg';

/** CardViewProps interface**/
export interface CardViewProps {
  skill: Skill,
  onClickFunction: ((skill: Skill) => void)| undefined,
  usable: boolean,
  isMinified: boolean,
}

/**
 * The Security Card View Component
 */
class CardView extends React.Component<CardViewProps> {
  /** Constructor using CardProps **/
  constructor(props: CardViewProps) {
    super(props);
  }

  /** wrepper for onClick calling specified callback with skill argument **/
  select = () => {
    if (this.props.onClickFunction) {
      this.props.onClickFunction(this.props.skill);
    }
  };

  /** Render the card view **/
  render() {
    let card = income;
    if (this.props.skill.cardType == 'preventive') {
      card = preventive;
    }
    if (this.props.skill.cardType == 'reactive') {
      card = reactive;
    }

    return (
      <div onClick={this.select}
        className={
          `CardView${this.props.isMinified?'-Minified':'-Full'}
          ${this.props.usable? '': 'CardDisabled'}`}
        style={{backgroundImage: `url(${card})`}}>
        <div className="CardName">
          {this.props.skill.label}
        </div>
        <div className='CardTags'>
          {
            this.props.skill.tags.map((tag, index)=>{
              return (<img key={index} src={TagIcons[tag]}></img>);
            })
          }
        </div>
        <div className="CardDescription">
          {this.props.skill.description}
          {this.props.skill.hintText?
           <HintWidget hintText={this.props.skill.hintText}
             inCard={true}/>:undefined
          }
        </div>
        <div className="CardEffect">
          {this.props.skill.effectText}
        </div>
      </div>
    );
  }
}

export default CardView;
