import React from 'react';
import './ProbabilityMeter.scss';
import GameState from '../logic/GameState';
import ProbabilityEffect from '../logic/effects/ProbabilityEffect';
import {observer} from 'mobx-react';
import {TagIcons} from 'src/api/SecurityCardsKRITIS/SecurityEventsApi';
import Dice from 'src/assets/SecurityCardsKRITIS/Dice.svg';
import Shield from 'src/assets/SecurityCardsKRITIS/Shield.svg';

interface ProbabilityMeterProps {
  gamestate: GameState,
}


interface ProbabilityMeterState {
  roll: number
  action: 'initial' | 'running' | 'done'
}

/** displays the diceroll and probability for events to occur */
@observer
class ProbabilityMeter extends
  React.Component<ProbabilityMeterProps, ProbabilityMeterState> {
  /** default constructor */
  constructor(props: ProbabilityMeterProps) {
    super(props);
    this.state = {
      roll: 0,
      action: 'initial',
    };
  }

  renderBricks = (isPositive: boolean) => {
    const event = this.props.gamestate.activeEvent;
    if (!event) {
      return [];
    }
    const elements = [];
    for (const effect of this.props.gamestate.activeEffects
        .effects.filter((value) => {
          const {probability} = value as ProbabilityEffect;
          return isPositive? probability > 0 : probability < 0;
        })) {
      const {tag, probability, skillName} = (effect as ProbabilityEffect);
      const imgSrc = TagIcons[tag];
      if (event.tags.includes(tag)) {
        elements.push((<div className='BrickWrapper' key={skillName}
          title={skillName}
          style={{width: `calc(${Math.abs(probability)}% - 5px)`}}>
          <div className="Brick"><img src={imgSrc} /></div>
          <div className="Label" >{skillName}</div>
        </div>));
      }
    }
    const defaultResistance = 100 - event.probability;
    if (isPositive && defaultResistance > 0) {
      elements.push(<div className='BrickWrapper' key={'default'}
        title={'Basisresistenz'}
        style={{width: `${defaultResistance}%`}}>
        <div className="Brick" />
        <div className="Label">Basisresistenz</div>
      </div>);
    }
    return elements;
  };

  /** Only show Shield icon if event is an incident */
  showShield() {
    return this.props.gamestate.activeEvent?.category == 'incident';
  }

  /** reset component to inital state on next week press */
  componentDidUpdate() {
    if (this.props.gamestate.showEventDescription &&
      this.state.action == 'done') {
      this.setState({action: 'initial', roll: 0});
    }
  }

  /** default render */
  render() {
    const event = this.props.gamestate.activeEvent;
    if (!event) {
      return <></>;
    }

    const roll = (this.props.gamestate.activeEvent?.roll ?? 0);
    const style = `@keyframes wave {
        0% {width: 0%;}
        100% {width: ${roll}% ;}
    }`;

    return (
      <div className='ProbabilityMeter'>
        <style>{style}</style>
        <span className='AttackLabel'>Angriffseffektivität</span>
        <img className={`Dice` +
          `${this.props.gamestate.probMeterIsMoving ? 'IsVisible' : ''}`}
        src={Dice} />
        <img className={`Shield` +
          `${this.showShield() ? 'IsVisible' : ''}`}
        src={Shield} />
        <div key='Attack' className='Attack'>
          <div key='AttackStrength' className={`AttackStrength
            ${this.props.gamestate.probMeterIsMoving ? 'moving' : ''}`}
          style={{width: `${this.state.roll}%`}}
          onAnimationEnd={() => {
            this.props.gamestate.toggleProbMeterAnimation();
            this.setState({roll: roll, action: 'done'});
            this.props.gamestate.handleEventActivation();
          }}
          />
          {this.renderBricks(false)}
        </div>
        <span className='ResistLabel'>Schutzmaßnahmen</span>
        <div className='Resist'>{this.renderBricks(true)}</div>
      </div>
    );
  }
}

export default ProbabilityMeter;
