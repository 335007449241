import React from 'react';

import './SecurityCardsLevelSelection.scss';
import 'pure-react-carousel/dist/react-carousel.es.css';
import avIcon from 'src/assets/SecurityCardsKRITIS/AG_Avail.svg';
import {ILevel} from 'src/api/SecurityCardsKRITIS/LevelsApi';

export interface SecurityCardsLevelSelectionProps {
  clickEvent: (SelectedLevel: ILevel) => void,
  levels: ILevel[]
}

/** The Explain Page, which can be displayed
 * just before the Quiz starts
 **/
class SecurityCardsLevelSelection extends
  React.Component<SecurityCardsLevelSelectionProps> {
  /** Constructor taking a onClick function for the button**/
  constructor(props: SecurityCardsLevelSelectionProps) {
    super(props);
  }
  /** render one level element */
  renderLevel(level: ILevel, index: number) {
    const description = [];
    for (let j = 0; j < level.description.length; j++) {
      const descriptionRender = <li key={j}>{level.description[j]}</li>;
      description.push(descriptionRender);
    }
    return (
      <div key={index} onClick = {() => this.props.clickEvent(level)}>
        <div className="LevelSelectionCard">
          <div className="LevelSelectionCardHeader">
            <p>{level.title}</p>
            <p>{level.subTitle}</p>
            {level.showAvailability? <img className="LevelSelectionCardIcon"
              title='KRITIS' src={avIcon}></img> : '' }
          </div>
          <div className="LevelSelectionCardContent">
            <ul className='LevelEntries'>
              {description}
            </ul>
          </div>
        </div>
      </div>
    );
  }
  /** Render The Explain text **/
  render() {
    return (
      <div className='LevelSelection'>
        <h1>Szenario Auswahl</h1>
        <div className='LevelSelectionContainer'>
          {this.props.levels.map((x, index) => this.renderLevel(x, index))}
        </div>
      </div>
    );
  }
}

export default SecurityCardsLevelSelection;
