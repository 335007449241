import React from 'react';
import {observer} from 'mobx-react';
import './AvailabilityIcons.scss';
import GameState from
  'src/components/SecurityCardsKRITIS/logic/GameState';
import RedundancyBar from './RedundancyBar';
import avCloud from
  'src/assets/SecurityCardsKRITIS/Availability_cloud.svg';
import avEnergy from
  'src/assets/SecurityCardsKRITIS/Availability_energy.svg';
import avNetwork from
  'src/assets/SecurityCardsKRITIS/Availability_network.svg';
import avServer from
  'src/assets/SecurityCardsKRITIS/Availability_server.svg';
import avWorker from
  'src/assets/SecurityCardsKRITIS/Availability_worker.svg';
import avProv from 'src/assets/SecurityCardsKRITIS/Availability_prov.svg';
import avIcon from 'src/assets/SecurityCardsKRITIS/AG_Avail.svg';

interface StatsViewProps {
    gameState: GameState
}

class AvailabilityIcons extends React.Component
<StatsViewProps> {
  /** default constructor */
  constructor(props: StatsViewProps) {
    super(props);
  }

  /** Render the StatView and its elements **/
  render() {
    const availabilityNetwork =
    this.props.gameState.activeEffects.
        availabilityLevels['Internet'];
    const availabilityWorker =
    this.props.gameState.activeEffects.
        availabilityLevels['Staff'];
    const availabilityEnergy =
    this.props.gameState.activeEffects.
        availabilityLevels['Power'];
    const availabilityCloud =
    this.props.gameState.activeEffects.
        availabilityLevels['Cloud'];
    const availabilityServer =
    this.props.gameState.activeEffects.
        availabilityLevels['Server'];
    const availabilityProv =
    this.props.gameState.activeEffects.
        availabilityLevels['IT-Serviceprovider'];

    return (
      <details open className="AvailabilityIconArea">
        <summary className='AvailabilityHeader'>
          Redundanz <img className="AvailabilityHeaderIcon"
            src={avIcon}></img> :</summary>
        <div className='AvailabilityDetailsContent'>
          <RedundancyBar icon={avNetwork} redundancy={availabilityNetwork}
            tooltip='Netzwerk'/>
          <RedundancyBar icon={avWorker} redundancy={availabilityWorker}
            tooltip='Mitarbeiter'/>
          <RedundancyBar icon={avEnergy} redundancy={availabilityEnergy}
            tooltip='Stromversorgung'/>
          <RedundancyBar icon={avCloud} redundancy={availabilityCloud}
            tooltip='Cloud'/>
          <RedundancyBar icon={avServer} redundancy={availabilityServer}
            tooltip='Server'/>
          <RedundancyBar icon={avProv} redundancy={availabilityProv}
            tooltip='Dienstleister'/>
        </div>
      </details>
    );
  }
}

export default AvailabilityIcons;
