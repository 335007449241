import React from 'react';
import Overlay from 'src/utils/Overlay/Overlay';
import CardView from '../CardView/CardView';
import EventView from '../EventView/EventView';
import History, {Turn} from '../logic/History';
import './HistoryView.scss';
import Event from 'src/components/SecurityCardsKRITIS/logic/cards/Event';

interface HistoryViewProps {
  history: History;
}
/** displays history data */
export default class HistoryView extends React.Component<HistoryViewProps> {
  /** default constructor */
  constructor(props: HistoryViewProps) {
    super(props);
  }

  /** creates overlay for eventview  */
  showUseableSkills(event:Event, ref:React.RefObject<Overlay>) {
    const skills = this.props.history.eventMap.get(event);
    if (skills== undefined) {
      return (<></>);
    }
    return (
      <Overlay ref={ref}>
        <div className='OverlayContainer'>
          <div>
            <h2>{event.label}</h2>
            Folgende Karten können gegen dieses
               Ereignis eingesetzt werden:
          </div>
          {skills.map((value) => (<CardView skill={value}
            key={value.name} usable={true}
            isMinified={false} onClickFunction={undefined} />))}
        </div>
      </Overlay>
    );
  }

  /** renders a turn in the game */
  displayTurn(turn: Turn, week:number) {
    const ref = React.createRef<Overlay>();
    return (
      <div key={week} className='Turn'>
        <h2>KW{week}:</h2>
        <div className={`EventWrapper ${
          this.props.history.
              eventMap.get(turn.event)!=undefined?'clickable':''}`}
        onClick={()=>ref.current?.toggle()}>
          <EventView event={turn.event} showEventDescription={false}/>
          {this.showUseableSkills(turn.event, ref)}
        </div>
        {turn.usedSkills.length > 0?(<h2>Eingesetzte Karten:</h2>):<></>}
        <div className='CardList'>
          {turn.usedSkills.map((value) => (<CardView skill={value}
            key={value.name} usable={true}
            isMinified={false} onClickFunction={undefined} />))}
        </div>
      </div>
    );
  }
  /** render function */
  render() {
    return (
      <div className='HistoryView'>
        <h1>Spielverlauf:</h1>
        <div>
          Mit einem Klick auf ein Schadensereignis erhalten Sie eine
          Übersicht über Karten die gegen das Ereignis eingesetzt werden können.
        </div>
        <div className='TurnList'>
          {this.props.history.history.map(
              (turn, index) => this.displayTurn(turn, index+1))}
        </div>
      </div>
    );
  }
}
