import React from 'react';
import './CardFinishOverview.scss';
import '../logic/GameData';
import {formatMoney} from 'src/utils/Formatters/Formatters';

interface CardFinishOverviewProps {
  money: number,
  kw: number,
  available: boolean,
}

/**
 * The finish Overview
 */
class CardFinishOverview extends React.Component<CardFinishOverviewProps> {
  /** Generic constructor **/
  constructor(props: CardFinishOverviewProps) {
    super(props);
  }
  /** Check if money is negative **/
  checkBalance() {
    if (this.props.money < 0) {
      return true;
    } else {
      return false;
    }
  }
  /** Plain render function **/
  render() {
    let statusMessage;
    if (this.checkBalance()) {
      statusMessage = 'Sie sind insolvent!';
    } else if (!this.props.available) {
      statusMessage = 'Ihre Verfügbarkeit ist zu gering!';
    } else {
      statusMessage = `Sie haben das Unternehmen für ${this.props.kw}
      Wochen erfolgreich geleitet`;
    }
    return (
      <div className="CardFinishOverview">
        <h1 className="OverviewHeadline">{'Spiel beendet'}</h1>
        <h2 className={`OverviewStatusHeadline
        ${(this.checkBalance() || !this.props.available)?
          'OverallDefeat': 'OverallSuccess'}`}>
          {statusMessage}
        </h2>
        <span className="FinishStat">
            Kontostand: {formatMoney(this.props.money)}
        </span>
      </div>
    );
  }
}

export default CardFinishOverview;
