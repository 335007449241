import {makeObservable, observable, action} from 'mobx';
import Skill from './cards/Skill';
import GameData from './GameData';
import GameState from './GameState';

/** Observable state of everything shop related */
export default class ShopState {
  shopVisible = false;
  shopAvailable = true;
  selectedSkill: Skill | undefined;
  insufficientFunds = false;
  cardsInShop: Skill[] = [];
  shopableSkills = ['Cyberversicherung', '2FAPolicy',
    'PasswortPolicy', 'ItDienstleisterSLA', 'AwarenessSchulung', 'WerbeAktion',
    'ModernisierungIT', 'PasswortPolicy2',
    'DynamischesBlockenBoesartigerAdressen',
    'DeceptionandDetection', 'DataAccessPolicy', 'Netzwerksegmentierung',
    'Ueberspannungsschutz', 'VPNPolicy',
    'KreditAufnehmen', 'USV', 'FestplattenEncryption', 'NACPolicy',
    'shopWeitereStromleitung', 'shopWeitereInternetleitung',
    'shopWeitererCloudAnbieter', 'shopWeitererServer',
    'shopMitarbeiterFortbildung', 'shopWeitererDienstleister'];
  shopableTutorialSkills = ['tutorialSkill1'];
  /** constructor sets up mobx observables */
  constructor() {
    makeObservable(this,
        {
          shopVisible: observable,
          cardsInShop: observable,
          insufficientFunds: observable,
          selectedSkill: observable,
          toggleShopVisibility: action,
          removeFromShop: action,
          handleCardSelect: action,
          setinsufficientFunds: action,
        },
    );
  }
  /** Hide/Show Shop **/
  toggleShopVisibility() {
    if (this.shopVisible) {
      this.shopVisible = false;
    } else if (this.shopAvailable) {
      this.shopVisible = true;
    }
  }

  /** Remove a card from the shop **/
  removeFromShop(card: Skill) {
    this.cardsInShop.splice(this.cardsInShop.indexOf(card), 1);
  }

  /** Refill the shop with cards **/
  refillShop(data: GameData) {
    this.cardsInShop = [];
    for (const name of this.shopableSkills) {
      this.cardsInShop.push(data.getSkill(name));
    }
  }
  /** Refill the shop with cards in tutorial**/
  refillTutorialShop(data: GameData) {
    this.cardsInShop = [];
    for (const name of this.shopableTutorialSkills) {
      this.cardsInShop.push(data.getSkill(name));
    }
  }
  handleCardSelect = (skill?: Skill) => {
    if (this.selectedSkill == skill) {
      this.selectedSkill = undefined;
    } else {
      this.selectedSkill = skill;
      this.insufficientFunds = false;
    }
  };

  setinsufficientFunds = () => {
    this.insufficientFunds = true;
  };
  /** buy skill from shop */
  buy(state: GameState) {
    if (this.selectedSkill == undefined) {
      throw Error('SelectedSkill is undefined');
    }
    /** Verify that user has sufficient funds to buy the card **/
    if ((this.selectedSkill.price > state.money)) {
      this.setinsufficientFunds();
      this.handleCardSelect(this.selectedSkill);
    } else {
      if (this.selectedSkill.isAutoPlay) {
        state.applySkill(this.selectedSkill);
      } else {
        state.skills.addSkill(this.selectedSkill);
      }
      state.shop.removeFromShop(this.selectedSkill);
      state.decreaseMoney(this.selectedSkill.price);
      this.handleCardSelect(this.selectedSkill);
    }
  }
}
